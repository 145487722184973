@import '~@beewise/theme/build/variables';

.empty-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: $gray-60;
    font-size: 12px;
    line-height: normal;
}
