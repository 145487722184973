@import '~@beewise/theme/build/variables';

.select-badge.select-field-root {
    border: 0;
    max-width: 96px;

    .select-field__control {
        border: 1px solid $gray-30;
        border-radius: 4px;
        min-height: 26px;
        height: 26px;
    }

    .placeholder {
        color: $grey-3;
        display: flex;
        align-items: center;
        margin-right: 8px;
        font-weight: 400;
    }

    .select-field__indicators:before {
        border-top-color: $grey-3;
        transform: translateY(-50%) !important;
    }

    .select-field__value-container {
        padding: 0 8px;
    }

    .select-field__menu,
    .menu-list--creatable {
        min-width: 260px;
        width: 100%;
        cursor: pointer;
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0;
        box-shadow: none;

        &-list {
            border: 1px solid $grey-6;
            border-radius: 4px;
            padding: 0;
        }
    }

    .select-field__option {
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 4px;
        height: fit-content;

        &:has(.active) {
            background-color: $gray-30;
        }
    }
}
