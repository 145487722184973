@import '~@beewise/theme/build/variables';

.ranches-filtering {
    display: flex;
    padding-bottom: 24px;
    align-items: center;
    gap: 4px;
    border-bottom: 1px solid $very-light-blue;

    &-icon {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        border: 1px solid $primary-orange;
        background: $orange-6;
        box-sizing: border-box;

        svg {
            min-width: 20px;
            min-height: 20px;
        }
    }

    .select-field__control {
        border: none;
        min-height: 32px;
        min-width: 150px;
        gap: 8px;

        &--menu-is-open {
            border-radius: 4px;
            background: $gray-active;
            overflow-x: hidden;
        }

        .select-field__single-value {
            color: $dark-black;
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
        }

        .select-field__value-container {
            padding: 4px 8px;
        }
    }
}
