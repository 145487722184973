@import '~@beewise/theme/build/variables';

.aside-panel {
    box-sizing: border-box;
    transform: translateX(0);
    transition: all 0.3s ease-out;
    z-index: 1002;
    left: 0;
    height: 100%;
    position: fixed;

    &.right {
        right: 0;
        left: auto;
        transform: translateX(0);

        &.closed {
            transform: translateX(100%);
            opacity: 0;
        }
    }

    &.closed {
        transform: translateX(-100%);
        opacity: 0;
    }

    &.full-screen {
        top: 0;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition:
        visibility 0s,
        opacity 0.3s;
    z-index: 1001;

    &.open {
        visibility: visible;
        opacity: 1002;
    }
}
