@import '~@beewise/theme/build/variables';

.map-view {
    width: 65%;
    padding: 24px;
    background-color: $purple;
    display: flex;
    flex: 2 1;

    .map-info {
        top: 56px;
    }

    &.create_location {
        #map div:not(.icon) {
            cursor:
                url('./location-dot.svg') 16 16,
                pointer !important;
        }

        #map .icon {
            cursor: pointer !important;
        }
    }

    &.add_label {
        #map div:not(.icon) {
            cursor:
                url('./add-label-icon.svg') 16 16,
                pointer !important;
        }

        #map .icon {
            cursor: pointer !important;
        }
    }
}
