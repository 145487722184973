@import '~@beewise/theme/build/variables';

.ranch-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 2;
    background-color: $purple;
}
