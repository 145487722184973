@import '~@beewise/theme/build/variables';

.add-ranch-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .add-ranch-text {
        color: $text-primary;
        font-style: normal;
        line-height: 28px;
    }
}
