@import '~@beewise/theme/build/variables';

.tooltip-marker-info {
    text-align: left;

    h3 {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
    }

    p {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    span {
        text-decoration: underline solid;
        text-underline-position: from-font;
        text-decoration-skip-ink: auto;
    }

    &-note {
        font-weight: 400;
    }
}
