@import '~@beewise/theme/build/variables';

.pin {
    .standard-pin {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grey;
        border: 1.25px solid $black;
        border-radius: 50%;
        padding: 4px;
        cursor: pointer;

        .pin-icon {
            width: 16px;
            height: 16px;
        }

        &.pin-type {
            background-color: $icons-yellow;
        }

        &.gate-type {
            background-color: $icons-light-green;
        }

        &.roadBlock-type {
            background-color: $road-block-red;
        }
    }

    .label-pin {
        background-color: $beewise-yellow;
        color: $white;
        padding: 2px 6px;
        border-radius: 4px;
        position: relative;
        font-size: 10px;
        font-family: 'Poppins', serif;
    }

    .label-pin-icon {
        svg {
            fill: $beewise-yellow;
            stroke: $beewise-yellow;

            path {
                stroke: $beewise-yellow;
            }
        }
    }
}
