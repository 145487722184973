@import '~@beewise/theme/build/variables';

#map-v2 {
    .performance-widget {
        position: absolute;
        background: $black;
        color: $white;
        display: flex;
        bottom: 24px;
        border-radius: 8px;
        left: 50%;
        transform: translateX(-50%);

        .row {
            justify-content: space-between;
            gap: 24px;
        }

        &__value_wrapper {
            gap: 4px;
            display: flex;
            align-items: center;
        }

        &__item {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 16px;
            width: 160px;
            min-width: 120px;
        }

        &__item:not(:last-child) {
            border-right: 1px solid $gray-disabled;
        }

        &__item-description {
            font-size: 12px;
        }

        &__item-icon {
            padding: 2px;
            border-radius: 8px;
            color: $beewise-yellow;
            background: $dark-yellow-background;
            width: 16px;
            height: 16px;
            stroke: $beewise-yellow;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}

@media (max-width: 1240px) {
    #map-v2 {
        .performance-widget {
            &__item {
                width: 124px;

                .row {
                    justify-content: space-between;
                    gap: 4px;
                }
            }
        }
    }
}
