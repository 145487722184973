@import '~@beewise/theme/build/variables';
.heat-map-timeframes {
    display: flex;
    flex-direction: row;
    gap: 0;
    top: unset;
    bottom: 32px;
    border-radius: 8px;
    padding: 4px;
    button {
        border: none;
        background: none;
        cursor: pointer;
        font-size: 12px;
        padding: 4px 24px;
        font-weight: 500;
        &.selected {
            background-color: $white;
            color: $dark-black;
            border-radius: 4px;
        }
        color: $white;
        &:not(.selected):not(:last-of-type) {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 4px;
                bottom: 4px;
                width: 1px;
                background-color: $gray-80;
            }
        }
    }
    svg {
        cursor: pointer;
        color: $white;
        padding: 0 4px;
        font-size: 10px;
        &:hover {
            color: $gray-80;
        }
    }
}
