@import '~@beewise/theme/build/variables';
.heat-map-legend {
    display: flex;
    align-items: center;
    gap: 8px;
    top: 78px;
    padding: 8px;
    .percentage-marks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 120px;
        font-size: 12px;
        color: $white;

        span {
            line-height: 1;
        }
    }

    .percentage-bar {
        width: 8px;
        height: 120px;
        border-radius: 2px;
        background: linear-gradient(
            rgba(3, 127, 3, 1),
            rgba(36, 195, 36, 1) 30%,
            rgba(138, 246, 138, 1) 50%,
            rgba(252, 255, 163, 1)
        );
    }
}
