@import '~@beewise/theme/build/variables';
.heat-map-info {
    bottom: 70px;
    top: unset;
    color: white;
    width: 200px;
    font-size: 12px;
    margin-right: 28px;
    line-height: 18px;
    padding: 8px;
    span {
        position: relative;
        color: $white;
    }
    svg {
        position: absolute;
        right: 0px;
        top: 2px;
        width: 12px;
        height: 12px;
        cursor: pointer;
    }
}

.heat-map-info-button {
    bottom: 70px;
    top: unset;
    border-radius: 50%;
    color: $white;
    padding: 0;
    cursor: pointer;
    svg {
        width: 16px;
        height: 16px;
        padding: 8px;
    }
}
