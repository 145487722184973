@import '~@beewise/theme/build/variables';

.ranch-item {
    padding: 12px 20px 0 0;
    box-sizing: border-box;
    position: relative;

    &:first-child {
        padding-top: 0;
    }

    &-content {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 16px;
        gap: 8px;
        background: $gray-0;
        border: 1px solid $light-gray-blue;
        border-radius: 5px;
        height: 100%;
        cursor: pointer;

        .ranch-item-menu {
            display: none;
            transition: display 0.2s ease-in-out;
            top: 8px;
            right: 8px;
            z-index: 10;
        }

        &:hover {
            .ranch-item-menu {
                display: block;
                opacity: 1;
                pointer-events: auto;
            }
        }

        &.hovered-ranch {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
        }

        &.active-ranch {
            background: $orange-light;
            border-color: $light-yellow;

            .btn-icon.secondary {
                &:hover {
                    background: #fff1d2;
                }

                &:active {
                    background: #f9e4b1;
                }
            }

            .select-field-root .select-field__control {
                background-color: $orange-light;
            }

            .ranch-item-menu {
                opacity: 1;
                pointer-events: auto;
            }
        }

        &-top {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $dark-black;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .ranch-item-content-top-right {
                display: flex;
                align-items: center;
                gap: 4px;

                .btn-icon {
                    width: 28px;
                    height: 28px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 20px;
                }
            }
        }

        &-bottom {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-style: normal;
            font-size: 12px;
            line-height: 20px;
            color: $dark-black;
            gap: 10px;

            &-item {
                display: flex;
                width: 200px;
                align-items: center;

                &:not(:first-child) {
                    position: relative;
                    padding-left: 10px;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 10px;
                        width: 1px;
                        background-color: $grey-6;
                    }
                }

                span {
                    color: $gray-80;
                    margin-right: 4px;
                }

                &-number {
                    color: $dark-black;
                    display: inline;
                }

                svg {
                    color: $honeydew-green;
                    margin-left: 4px;
                }

                .red-data {
                    color: $crimson-red;
                }
            }
        }
    }
}

.tooltip-left-align {
    text-align: left;
}

.select-option-status {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: flex-start;
    cursor: pointer;
}

.status-icon {
    min-width: 6px;
    min-height: 6px;
    border-radius: 50%;
    margin-right: 8px;

    &-option {
        margin: 4px 0;
    }

    &--active {
        background-color: $honeydew-green;
    }

    &--draft {
        background-color: $gray-60;
    }

    &--inactive {
        border: 1px solid $gray-60;
        background-color: transparent;
    }
}
