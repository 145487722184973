@import '~@beewise/theme/build/variables';

.ranches-container {
    display: flex;
    height: calc(100vh - #{$app-header-height});
    flex-direction: column;
    background-color: $white;
    box-shadow: 0 6px 30px 0 $blue-shadow;
    position: relative;
    width: 35%;
    padding: 24px;
    flex: 1;
}
