@import '~@beewise/theme/build/variables';

.grower-view {
    display: flex;
    padding: 36px;
    background-color: $white;
    gap: 36px;
    flex: 1;

    &-content {
        flex: 2;
    }
}

.approval-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: 20px 30px;
    border: 1px solid $grey-7;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05);

    .ranch-approval-text {
        padding: 0;
        color: $text-secondary;
    }
}
