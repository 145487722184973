@import '~@beewise/theme/build/variables';

.locations-marker {
    cursor: pointer;
    border-radius: 50%;
    height: 20px;
    border: 1px solid $white;
    color: $white;
    position: relative;
    box-sizing: border-box;
    z-index: 2;

    &-wrapper {
        border-radius: 50%;
        &.weak {
            border: 6px solid rgba(255, 144, 76, 0.6);
        }
        &.medium {
            border: 6px solid rgba(253, 186, 18, 0.6);
        }

        &.strong {
            border: 6px solid rgba(117, 243, 121, 0.6);
        }
    }

    &.marker-transparent {
        opacity: 0.8;
    }

    &.multi {
        width: 40px;
        display: flex;
        border-radius: 88px;
        align-items: center;
    }

    &.single {
        width: 20px;
        border-radius: 50%;
    }

    .icon {
        position: relative;
        width: 100%;
        height: 100%;

        svg {
            fill: $white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 16px;
            height: 16px;
            padding: 2px;
            box-sizing: border-box;
        }
    }

    &-data {
        position: absolute;
        text-align: center;
        top: -40%;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        width: max-content;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
