@import '~@beewise/theme/build/variables';

.btn {
    &-icon-v2 {
        background-color: transparent;

        &:hover {
            background-color: transparent;

            svg {
                color: $primary-orange-hover;
            }
        }

        &:active {
            background-color: transparent;

            svg {
                color: $primary-orange-active;
            }
        }

        &:disabled {
            background-color: transparent;

            svg {
                color: $button-disabled;
            }
        }
    }

    &-active {
        svg {
            color: $primary-orange-active;
        }
    }

    svg {
        width: 16px;
        height: 16px;
    }
}
