@import '~@beewise/theme/build/variables';

.editable-list-item {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 12px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.1s ease;

    &-content {
        display: flex;
        flex: 1;
        align-items: center;
        gap: 8px;
    }

    .btn-icon {
        visibility: hidden;
        margin-left: auto;
        cursor: pointer;
    }

    .btn-container {
        display: flex;
        gap: 8px;
        margin-left: auto;
    }

    &:hover {
        background-color: $gray-10;

        .btn-icon {
            visibility: visible;
        }
    }
}
