@import '~@beewise/theme/build/variables';

$padding-horizontal: 24px;
$border: 1px solid $gray-20;

.padding {
    padding: $padding-horizontal 36px $padding-horizontal 28px;
}

.panel {
    width: 35%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > *:not(.header):not(.footer) {
        display: flex;
        flex-direction: column;
        padding: 0 $padding-horizontal;
        margin-top: 24px;
    }

    .header {
        @extend .padding;
        border-bottom: $border;
    }

    .footer {
        @extend .padding;
        margin-top: auto;
        display: flex;
        border-top: $border;
        box-sizing: border-box;
    }
}
