@import '~@beewise/theme/build/variables';
.timeline-graph {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    .point {
        position: relative;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        top: -2.5px;
        z-index: 2;
        box-sizing: border-box;

        &-small {
            width: 6px;
            height: 6px;
            top: -2.5px;
        }

        &-large {
            width: 10px;
            height: 10px;
            top: -4.5px;
        }

        &-name {
            position: absolute;
            font-size: 10px;
            color: $gray-05;
            top: 8px;
            &-main {
                text-transform: capitalize;
            }
            &-secondary {
                white-space: nowrap;
            }
        }
    }

    .dashed {
        margin: 0 8px;
    }

    background-size: 100% 1px;
    background-position: top left;
    background-repeat: no-repeat;
}

.ui.popup.timeline-tooltip {
    text-transform: capitalize;
    font-size: 10px;
}
