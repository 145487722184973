@import '~@beewise/theme/build/variables';

.right-panel-ranch {
    .divider {
        margin-top: 16px;
        position: relative;
        border-top: 1px solid $grey-6;
        width: 100%;

        &:after,
        &:before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: '';
            height: 1px;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-bottom-color: #f6f6f5;
            border-width: 10px;
            margin-left: -10px;
        }

        &:before {
            border-bottom-color: $grey-6;
            border-width: 11px;
            margin-left: -11px;
        }
    }

    .fa-share-from-square {
        position: absolute;
        right: 0;
        bottom: -4px;
        cursor: pointer;
    }

    .card-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &:last-child {
            border-bottom: none;
        }

        &-name {
            font-size: 14px;
            color: $kona-grey;
            opacity: 0.8;
        }

        &-value {
            font-size: 14px;
            color: $yellow-dark;
            font-weight: 500;
            text-align: right;
            max-width: 60%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
