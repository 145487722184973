@import '~@beewise/theme/build/variables';

.ranch-info {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .bold {
        font-weight: 500;
    }

    .text {
        color: $text-secondary;
        font-size: 16px;
    }
}

.delivery-details {
    font-size: 16px;
    color: $text-secondary;

    h3 {
        font-size: 16px;
    }

    .details-button {
        font-size: 16px;
        background: none;
        border: none;
        color: $static-partition;
        cursor: pointer;
        padding: 8px 0;
        display: flex;
        align-items: center;
        gap: 5px;

        &:hover {
            text-decoration: underline;
        }
    }

    .delivery-list {
        margin-top: 10px;

        ul {
            list-style-type: disc;
            padding-left: 20px;
            margin: 8px 0;
        }

        .delivery-item {
            margin-bottom: 8px;
        }
    }
}
