@import '~@beewise/theme/build/variables';

.status-option {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    &-name {
        color: $black;
    }

    &-title {
        color: $grey-3;
    }
}
