@import '~@beewise/theme/build/variables';

.details-card {
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        h3 {
            margin-bottom: 6px;
        }

        .v2.btn-blue.btn-text {
            font-size: 14px;
        }
    }

    &-info {
        display: flex;
        gap: 40px;
        justify-content: space-between;
    }

    &-column {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .card-item {
        display: flex;
        gap: 8px;
        align-items: center;
        color: $text-secondary;
        font-size: 14px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}
