.ranch-form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 32px;
    margin-top: 24px;

    .row {
        gap: 16px;
    }

    .select-field-root,
    .text-field-root {
        flex: 1 0;
    }

    .btn {
        width: fit-content;
    }

    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}
