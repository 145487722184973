@import '~@beewise/theme/build/variables';

.edit-ranch-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $gray-20;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;

    &-right-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        margin-left: auto;

        .v2.btn-yellow.btn-contained {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
        }
    }
}
