@import '~@beewise/theme';
@import '~react-circular-progressbar/dist/styles.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-phone-input-2/lib/material.css';

@import '~@beewise/icon/build/lib/Icon';
@import '~@beewise/button/build/lib/Button';
@import '~@beewise/toast/build/lib/Toast';
@import 'Typography';

#app {
    font-size: 14px;
    min-height: 100vh;

    #view-v2 {
        display: flex;
        overflow-y: hidden;
        height: calc(100vh - #{$app-header-height});
    }

    #bee-toast-container {
        font-weight: 600;
        font-size: 14px;

        > div {
            width: auto;
        }

        .Toastify__toast {
            font-family: 'Poppins', serif;
            padding: $spacing 16px;
            border-radius: 8px;

            &--default {
                background: $blue-old;
                color: $white;
            }
        }
    }
}

.app {
    .side-menu {
        top: $app-header-height;
    }

    .no-bhome-view {
        padding: $spacing;
        font-size: 16px;
        font-weight: 500;
    }

    .view {
        width: 100%;
        padding: $spacing;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 24px;
    }
}

.preloader-wrapper {
    display: flex;
    width: 100%;
    overflow-y: scroll;
    flex: 1;

    .preloader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner {
            path {
                stroke: $brand;
            }
        }
    }
}

.ui.table {
    color: $gray-09;

    thead tr th {
        color: $kona-grey;
    }
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.small-text {
    font-size: 10px;
    color: $gray-06;
    font-weight: normal;
}

.bold {
    font-weight: 600;
}

.row {
    display: flex;
}

.beewise-toast.toast-default {
    background-color: $place-green;
    color: white;
}

body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
}

body ::-webkit-scrollbar-thumb {
    background-color: $grey-6;
    border-radius: 2px;
    width: 4px;
}

body ::-webkit-scrollbar-track {
    background-color: transparent;
}

.thumb-vertical {
    background-color: $grey-6;
    border-radius: 2px;
    width: 4px !important;
    z-index: 10;
}

.thumb-vertical-list {
    background-color: $grey-6;
    border-radius: 2px;
    width: 4px;
    left: -8px;
}

.gm-style-iw-c,
.gm-style-iw-c:after,
.gm-style-iw-t:after,
.gmnoprint {
    display: none;
}

.updated-select-field {
    .select-field__multi-value {
        display: flex;
        align-items: center;
        padding: 4px 12px;
        gap: 4px;
        background: $purple-light;
        border-radius: 19px;

        &__label {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $gray-80;
        }

        &__remove {
            display: none;
        }
    }
}
