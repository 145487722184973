@import '~@beewise/theme/build/variables';

.report-container {
    border: 1px solid $grey-7;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05);
    padding: 25px;
    height: fit-content;
    width: fit-content;
    margin: 36px 0 0 0;

    .header {
        color: $text-secondary;
        font-size: 16px;
        font-weight: 600;
        padding: 0 0 20px 0;
    }

    .description {
        color: $text-secondary;
        font-size: 14px;
        font-weight: 400;
    }

    .custom-tooltip {
        background-color: $gray-100;
        border-radius: 4px;
        padding: 4px 8px;

        .intro {
            font-size: 10px;
            line-height: 14px;
            font-weight: 500;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            color: $white;
        }
    }

    @media (max-width: 1000px) {
        padding: 15px;
        margin: 20px 0;

        .header {
            font-size: 14px;
        }

        .description {
            font-size: 12px;
        }
    }
}

.rounded-container {
    border-radius: 12px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .title {
        font-weight: 700;
        font-size: 29px;
        line-height: 25px;
        color: $icons-dark-green;
    }

    .info-block {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $text-primary;
        gap: 3px;

        .tooltip-icon {
            cursor: pointer;
        }
    }
}
