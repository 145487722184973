@import '~@beewise/theme/build/variables';

.ranches-performance-container-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
    background: $purple;
    border-radius: 8px;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: $gray-60;
    width: 100%;
    white-space: nowrap;
    cursor: default;

    span {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $dark-black;
    }
}
