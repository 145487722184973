.installers {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .v2.btn-small.btn.btn-text {
        font-size: 14px;
        width: fit-content;
    }
}
