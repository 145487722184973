.map-search-v2 {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .svg-inline--fa {
        color: white;
    }

    input {
        margin: 0;
        outline: none;
        font-size: 12px;
        line-height: 22px;
        color: white;
        font-family: 'Poppins', serif;
        border: 0;
        border-bottom: 1px solid white;
        appearance: none;
        background-color: transparent;
        box-sizing: border-box;
        width: 0;
        visibility: hidden;

        &.active {
            width: 100%;
            visibility: visible;
        }
    }
}
