@import '~@beewise/theme/build/variables';

.ranch-icon {
    width: 8px;
    height: 8px;
    border: 1px solid $white;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        width: 10px;
        height: 10px;
    }

    &.empty {
        background-color: $static-partition;
    }

    &.partial {
        background-color: $beewise-yellow;
    }

    &.placed {
        background-color: $brood-capped-v2;
    }
}

.tooltip-marker {
    font-size: 12px;
    line-height: normal;

    &-name {
        font-weight: 600;
    }

    &-info {
        font-weight: 400;
    }
}
