@import '~@beewise/theme/build/variables';

#view-v2 {
    .toolbar {
        display: flex;
        background-color: $black;
        height: 48px;
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 1;
        top: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        gap: 20px;
        padding: 12px 20px;
        box-sizing: border-box;

        &-right {
            margin-left: auto;
            display: flex;
            gap: 20px;
        }

        .btn-icon {
            cursor: pointer;

            svg {
                color: $white;
                height: 16px;
                width: 16px;
                position: relative;
            }
        }

        .btn-toolbar {
            border-radius: 50%;
            padding: 4px;

            path {
                stroke: $white;
            }

            &:hover {
                background-color: $gray-08;
            }

            &.btn-active {
                svg {
                    color: $beewise-yellow;
                }

                path {
                    stroke: $beewise-yellow;
                }
            }
        }

        .border-button {
            cursor: pointer;
            border: 1px solid $gray-60;
            padding: 4px 8px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            gap: 4px;

            .btn-icon {
                padding: 0;
                margin-left: 4px;
            }
        }
    }
}
