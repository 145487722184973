@import '~@beewise/theme/build/variables';

.beekeepers {
    display: flex;
    flex-direction: column;
    gap: 12px;

    svg {
        color: $gray-80;
    }

    &-row {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .v2.btn-small.btn.btn-text {
        font-size: 14px;
    }

    .section-header {
        margin-bottom: 4px;
    }

    .checkbox-label {
        font-size: 14px;
        color: $gray-80;
    }

    .select-field-root,
    .text-field-root {
        flex: 1;
    }

    .btn {
        width: fit-content;
    }

    &-edit-row {
        display: flex;
        align-items: center;
        gap: 12px;

        .btn-icon.primary {
            padding: 0;
            width: 20px;
            height: 20px;

            svg {
                width: 100%;
                height: 100%;
                color: $gray-50;

                &:hover {
                    color: $gray-60;
                }

                &:active {
                    color: $grey-3;
                }
            }
        }
    }

    .beekeeper-color {
        width: 18px;
        height: 5px;
        border-radius: 20px;
        margin-right: 2px;
    }

    .beekeeper-badge {
        font-size: 12px;
        color: $gray-80;
        padding: 8px;
        background: $purple-light;
        border-radius: 4px;
    }
}
