.right-panel-ranch {
    .card {
        .delivery-header {
            font-size: 14px;
            font-weight: 500;
        }

        .timeline-graph {
            margin-top: 2px;
        }

        .margined-top {
            margin-top: 4px;
        }
    }
}
