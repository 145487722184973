@import '~@beewise/theme/build/variables';

.ranches-list {
    position: relative;
    overflow: hidden;
    will-change: transform;
    direction: ltr;
    height: calc(100vh - #{$app-header-height});
    padding-right: 20px;
}
