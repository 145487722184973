@import '~@beewise/theme/build/variables';

.report-container {
    .custom-legend {
        margin-top: 20px;
        &-block {
            display: flex;
            gap: 50px;
            justify-content: flex-start;
        }
        &-list {
            flex-direction: column;
        }
        ul {
            display: flex;
            list-style: none;
            justify-content: flex-start;
            gap: 14px;
            margin: 0;
            padding: 0;

            li {
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                font-size: 14px;

                .legend-icon {
                    width: 20px;
                    height: 5px;
                    border-radius: 12px;
                    margin-right: 10px;
                    display: inline-block;
                }

                .pre-bloom {
                    background-color: $grey-7;
                }

                .activity {
                    background-color: $primary-blue;
                }

                .tooltip-icon {
                    cursor: pointer;
                    margin: 0 0 0 4px;
                }

                .orange-icon {
                    background-color: $beewise-yellow;
                }

                .blue-icon {
                    background-color: $primary-blue;
                }

                .brown-icon {
                    background-color: $brown-dark;
                }

                .green-icon {
                    background-color: $capped-brood;
                }

                .primary-green-icon {
                    background-color: $open-brood;
                }

                .light-green-icon {
                    background-color: $yellow-light;
                }

                .red-icon {
                    background-color: $road-block-red;
                }
            }
            .text {
                color: $text-secondary;
            }
        }
    }
}
