@import '~@beewise/theme/build/variables';

.company-users-panel {
    background: $white;
    width: 40%;
    height: calc(100vh - #{$app-header-height});
    padding: 24px;
    box-shadow:
        3px 0 16px 4px rgba(0, 0, 0, 0.05),
        3px 0 16px 4px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    border-radius: 8px;

    .company-users-panel-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 10px;
        position: relative;
    }

    .header-row {
        position: sticky;
        top: 0;
        z-index: 10;
        background: $white;
        padding: 14px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            margin: 0;
            font-size: 20px;
        }

        .close-button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 18px;
            padding: 0;

            &:focus {
                outline: none;
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .form-wrapper {
        height: 100%;
    }

    .company-users-form {
        flex: 1;
        overflow-y: auto;
        padding: 0 14px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .form-section,
        .users-section {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .users-section {
            padding-bottom: 80px;
        }

        .form-field {
            margin: 0;
            width: 100%;
        }

        .user-form-section {
            padding-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .user-header-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 0;

                h3 {
                    margin: 0;
                    font-size: 18px;
                }
            }

            .remove-user-button {
                background: none;
                border: none;
                cursor: pointer;
                font-size: 16px;
                color: $gray-60;

                &:hover {
                    color: $red;
                }

                &:focus {
                    outline: none;
                }
            }

            .user-fields {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
            }

            .buttons-row {
                display: flex;
                justify-content: space-between;
                gap: 10px;
            }

            h3 {
                font-weight: 500;
            }
        }
    }

    .footer-row {
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 10;
        background: $white;
        padding: 14px 0 0 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;

        button {
            padding: 10px 20px;
            font-size: 14px;
        }
    }
}
