@import '~@beewise/theme/build/variables';

.ranch-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-block {
        display: flex;
        align-items: center;
        gap: 16px;

        &-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $dark-black;
        }

        &-data {
            padding: 4px 12px;
            background-color: $purple-light;
            border-radius: 19px;
        }

        &-text {
            font-size: 12px;
            line-height: 16px;
            color: $black-opacity-70;
        }

        &-manage {
            display: flex;
            gap: 8px;
            align-items: center;

            .btn-icon {
                width: 28px;
                height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
            }
        }
    }
}
