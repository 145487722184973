@import '~@beewise/theme/build/variables';

.gm-style-iw-c,
.gm-style-iw-c:after,
.gm-style-iw-t:after,
.gmnoprint {
    display: none;
}

#map-v2 {
    width: 100%;
    height: 100%;
    position: relative;

    .google-map {
        border-radius: 8px;
    }

    .empty {
        background-color: $static-partition;
    }

    .partial {
        background-color: $beewise-yellow;
    }

    .placed {
        background-color: $brood-capped-v2;
    }
}
