@import '~@beewise/theme/build/variables';

.ranches-performance-container {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 8px;
    width: 100%;
    padding-top: 32px;
}
