@import '~@beewise/theme/build/variables';

.v2.card {
    border-radius: 8px;
    padding: 16px;
    background-color: $purple;

    &-outlined {
        border: 1px solid $light-gray-blue;
    }
}
