@import '~@beewise/theme/build/variables';
@import '~@beewise/theme/build/reusables';

.main-header {
    background-color: $black;
    height: $app-header-height;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px 0 20px;
    box-sizing: border-box;
    position: relative;

    .logo-container {
        padding: 10px 0 10px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .app-env {
            margin-left: 12px;
            border-radius: 4px;
            padding: 4px 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 11px;
            line-height: 16px;
            color: $black;

            &.beta {
                background-color: $grey-7;
            }
        }
    }

    .nav-list {
        margin-left: 56px;

        a {
            color: $white;
            text-transform: capitalize;
            text-decoration: none;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;

            &:hover {
                color: $brand;
            }

            &.active {
                color: $brand;
            }
        }

        &-disabled {
            pointer-events: none;
        }

        a + a {
            margin-left: 45px;
        }
    }

    .main-menu {
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    .user-icon {
        border-radius: 16px;
        padding: 4px;

        svg {
            color: $white;
            width: 14px;
            height: 14px;
        }

        &:hover {
            background-color: $gray-90;

            svg {
                color: $white;
            }
        }
    }
}

html:has(.main-menu-item) {
    #menu-root .menu.tooltip-content {
        min-width: 140px;
        padding: 0;
        min-height: 40px;

        .menu-item {
            padding: 0;
        }

        .main-menu-item {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 12px;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;

            .user-icon {
                background-color: $gray-80;
            }
        }
    }
}
