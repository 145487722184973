@import '~@beewise/theme/build/variables';

.ranch-details-container-item {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    flex: 1;
    border-radius: 8px;
    background: $white;
    height: 100%;
    box-sizing: border-box;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 24px;

        &-title {
            display: flex;
            align-items: center;
            gap: 8px;
            color: $dark-black;
            font-size: 14px;
            font-weight: 600;
            height: 20px;
        }
    }

    &-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        &-info {
            display: flex;
            align-items: start;
            gap: 8px;
            color: $gray-80;
            font-size: 14px;
            line-height: normal;

            &-icon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &-beekeeper {
            display: flex;
            padding: 0 4px;
            align-items: center;
            gap: 12px;
            align-self: stretch;

            span {
                width: 18px;
                height: 5px;
                border-radius: 20px;
            }

            &-details {
                span {
                    color: $gray-80;
                    font-weight: 300;
                }

                &-separator {
                    margin: 0 8px;
                    display: inline;
                }
            }
        }

        .empty-data-container {
            height: 130px;
        }
    }
}
