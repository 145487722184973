@import '~@beewise/theme/build/variables';

.left-panel {
    position: relative;
    box-shadow: 0 6px 30px 0 $blue-shadow;
    flex: 1 1;

    hr {
        width: 100%;
        color: $gray-20;
        background-color: $gray-20;
        margin: 24px 0;
        height: 1px;
        border: none;
    }
}
