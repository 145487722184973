@import '~@beewise/theme/build/variables';

h1,
.h1 {
    font-size: 20px;
    font-weight: 600;
}

h2,
.h2 {
    font-size: 16px;
    font-weight: 600;
}

h3,
.h3 {
    font-size: 14px;
    font-weight: 600;
}

p,
.p {
    font-size: 14px;
    font-weight: 400;
}

.text-xs {
    font-size: 12px;
}

.text-sm {
    font-size: 13px;
}

.text-secondary {
    color: $gray-80;
}

.subheader {
    color: $gray-60;
    font-size: 14px;
}
